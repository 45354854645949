// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-1-curso-python-js": () => import("./../src/pages/1-curso-python.js" /* webpackChunkName: "component---src-pages-1-curso-python-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elas-na-segep-js": () => import("./../src/pages/elas-na-segep.js" /* webpackChunkName: "component---src-pages-elas-na-segep-js" */),
  "component---src-pages-elas-na-senec-js": () => import("./../src/pages/elas-na-senec.js" /* webpackChunkName: "component---src-pages-elas-na-senec-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-i-smstem-js": () => import("./../src/pages/i-smstem.js" /* webpackChunkName: "component---src-pages-i-smstem-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investimentos-js": () => import("./../src/pages/investimentos.js" /* webpackChunkName: "component---src-pages-investimentos-js" */),
  "component---src-pages-lives-2020-js": () => import("./../src/pages/lives2020.js" /* webpackChunkName: "component---src-pages-lives-2020-js" */),
  "component---src-pages-members-js": () => import("./../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-roda-investimentos-online-js": () => import("./../src/pages/roda-investimentos-online.js" /* webpackChunkName: "component---src-pages-roda-investimentos-online-js" */),
  "component---src-pages-roda-investimentos-js": () => import("./../src/pages/roda-investimentos.js" /* webpackChunkName: "component---src-pages-roda-investimentos-js" */),
  "component---src-pages-ws-saude-emocional-js": () => import("./../src/pages/ws-saude-emocional.js" /* webpackChunkName: "component---src-pages-ws-saude-emocional-js" */)
}

